import Axios from 'axios';

export * from 'axios';

const axios = Axios.create();

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response) {
      const { data } = err.response;
      const objData = { data };
      throw objData;
    } else {
      throw new Error('Unhandled server error!');
    }
  },
);

export default axios;
